<template>
  <div>
    <b-card title="Create Graph">
      <b-card
        :title="graph.title"
        border-variant="light"
        v-for="(graph, graphIndex) in graphs"
        :key="graph.id"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group label="Graph Title" label-for="basicInput">
              <b-form-input
                v-model="graph.title"
                id="basicInput"
                placeholder="Enter the graph title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Graph Metrics" label-for="basicInput">
              <v-select
                v-model="graph.metrics"
                multiple
                label="Metrics"
                :options="metrics"
                :deselectFromDropdown="true"
                placeholder="Select the metrics"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-for="(metric, index) in graph.metrics"
              :key="metric"
              class="d-flex align-items-center"
              :class="index > 0 ? 'mt-2' : ''"
            >
              <span>
                <b-badge pill variant="primary"> {{ metric }} </b-badge>
              </span>
              <b-form-input
                v-model="graphs[graphIndex].metricsConfig[metric].label"
                class="ml-2 label-field"
                placeholder="Enter the Label"
              />
              <compact-picker
                class="ml-2"
                v-model="graphs[graphIndex].metricsConfig[metric].color"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>

      <div class="text-center">
        <b-button @click="addGraph" size="lg" variant="primary"
          >Add Graph <feather-icon size="22" icon="PlusIcon"></feather-icon
        ></b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Compact } from "vue-color";

export default {
  data() {
    return {
      metrics: ["ga:users", "ga:newUsers"],
      graphs: [],
      colors: "",
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    compactPicker: Compact,
    BBadge,
  },
  methods: {
    addGraph() {
      this.graphs.push({
        id: this.graphs.length ? this.graphs[this.graphs.length - 1].id + 1 : 1,
        title: "",
        metrics: [],
        metricsConfig: {},
      });
    },
  },
  watch: {
    graphs: {
      handler: function (newGraphs) {
        for (let i = 0; i < newGraphs.length; i++) {
          const graph = newGraphs[i];
          const graphMetrics = graph.metrics;

          for (let j = 0; j < graphMetrics.length; j++) {
            const metric = graphMetrics[j];
            if (!graph.metricsConfig[metric]) {
              this.$set(this.graphs[i].metricsConfig, metric, {
                label: "",
                color: "",
              });
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.label-field {
  max-width: 200px;
}
</style>